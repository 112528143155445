import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
// import EditFormDialog from './EditFormDialog';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import config from '../../config';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // Importer la localisation française
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ReservationsState } from '../../redux/reservations/reducers';
import { loadFacturesDownloadThunk, makeFatureByIdThunk } from '../../redux/reservations/thunk';
import { ResolverOptions, ResolverResult, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInput } from '../../components';

registerLocale('fr', fr); // Enregistrer la localisation française

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

interface IFactureDownload {
  _id: string;
  userId: string;
  nomFichier: string;
  date: string;
  typeFacturation: string;
  typeFacturationNom: string;
  numeroFacture: number;
}

const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
];

const FactureTable = () => {
  const dispatch = useAppDispatch();
  const [selectedFacture, setSelectedFacture] = useState<IFactureDownload | undefined>(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { facturesDownload, showNewAndEditReservationModal } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;

  const handleDownloadOne = (url: any) => {
    const fileUrl = url; // Remplacez par l'URL de votre fichier
console.log("url");
console.log(url);
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        console.log(link)
        link.href = url;
        link.setAttribute('download', 'facture.pdf'); // Remplacez par le nom que vous souhaitez donner au fichier
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => console.error('Erreur lors du téléchargement du fichier', error));
  };
  const handleOpenFile = (url: any) => {
    const fileUrl = url; // Remplacez par l'URL de votre fichier
    console.log("url");
    console.log(url);
    
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        window.open(url, '_blank'); // Ouvre le contenu dans une nouvelle fenêtre
      })
      .catch(error => console.error('Erreur lors du chargement du fichier', error));
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      paymentIntentId: yup.string().required("Veuillez saisir un id de paiement"),
      // numReservation: yup.string().required("Veuillez saisir un numérode location"),
    })
  );
  
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: {},
  });

  // const { handleSubmit, register } = useForm(); // Utilisation de useForm pour gérer le formulaire

  interface IFormValue {
    paymentIntentId: string;
    numReservation: string;
  }
  // Appel de la fonction
  // handleOpenFile("URL_DU_FICHIER");
  
  const columns = useMemo(() => [
    
    {
      Header: "Date",
      accessor: 'date',
      sort: true,
    },
    {
      Header: "Type de facturation",
      accessor: 'typeFacturationNom',
      sort: true,
    },
    {
      Header: "Numéro de facture",
      accessor: 'numeroFacture',
      sort: true,
    },
    {
      id: "Actions",
      accessor: '',
      Header: "Action",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <div className="d-flex align-items-center gap-2">
                        <a href= {row?.original.absoluteNomFichier} style={{textDecoration: 'none'}} target="_blank">
                         
            <button
              className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                // handleOpenFile(row?.original.absoluteNomFichier);
              }}
            >
              <span className="mr-2">
                <i className="bi bi-eye-fill"></i>
              </span>
            </button>
            </a>
          </div>
        );
      },
      disableGlobalFilter: true,
    },
  ], []);

  const [loading, setLoading] = useState(false); // État local pour contrôler l'affichage du loader


  const factures: IFactureDownload[] = [
    // ... Vos données de facture ici ...
  ];
  // const { register, handleSubmit, errors } = useForm();

  console.log(facturesDownload)

  useEffect(() => {
    // Charger les données nécessaires pour la table ici si nécessaire
  }, []);
  useEffect(() => {
    if (facturesDownload.length === 0) {
      setLoading(true);
      dispatch(loadFacturesDownloadThunk({}));
      setLoading(false);
    }
  }, [dispatch, facturesDownload.length]);

  
  const handleDownload = async () => {
    setLoading(true);
    try {
      setLoading(true); // Démarrez le loader au début de la soumission du formulaire
      const response = await axios.post(`${axios.defaults.baseURL}/admin/reservations/facture`, {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }, {
        responseType: 'arraybuffer', // Important for binary data
      });

      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloadedFiles.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading files', error);
    }finally{
      setLoading(false);
    }
    
  };

  // const [paymentIntentId, setPaymentIntentId] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Vous pouvez effectuer des actions avec le paymentIntentId ici, par exemple, l'envoyer à votre serveur via une requête HTTP
  //   console.log('paymentIntentId:', paymentIntentId);
  // };

  // const onSubmit = useCallback(
  //   (data) => {
  //     console.log("azerty")
  //     console.log(data)
  //     console.log('viens dff')
  //     const contraventionData = { ...data };
  //     dispatch(makeFatureByIdThunk(contraventionData));
  //     // handleClose(); // Ferme le modal après la soumission du formulaire
  //   },
  //   [dispatch]
  // );

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true); // Démarrez le loader au début de la soumission du formulaire
      try {
        await dispatch(makeFatureByIdThunk(data));
        dispatch(loadFacturesDownloadThunk({}));
      } catch (error) {
        console.error("Erreur lors de la soumission du formulaire :", error);
      } finally {
        setLoading(false); // Arrêtez le loader à la fin de la soumission du formulaire
      }
    },
    [dispatch]
  );

  const [immatriculation, setImmatriculation] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);


  useEffect(() => {
    if(immatriculation) {
      if(immatriculation.length >= 3) {
        dispatch(loadFacturesDownloadThunk({status: statusFilter, immatriculation: immatriculation}));
      }
    } else {
      dispatch(loadFacturesDownloadThunk({status: statusFilter}));
    }
  }, [dispatch, immatriculation]);


   // Fonction pour gérer la soumission du formulaire
  //  const onSubmit = (data as any) => {
  //   // Traitement des données soumises
  //   console.log('Données soumises :', data);
  // };
  // const { paymentIntentId } = watch();

  return (
    <>
      <PageTitle
        breadCrumbItems={[]}
        title={"Factures"}
      />
       

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title">Facture</h4>
              <Row>
              <div className="col-6 col-md-3 mb-2">
          <select className="form-select" aria-label="Default select example" onChange={(v) => {
            //console.log("Select changed ", v);
            setStatusFilter(v.target.value === "x" ? undefined : v.target.value as string | undefined)
          }}>
            <option selected value="x">Tout afficher</option>
            {/**<option value="PAYMENT_DONE">Paiement effectué</option>*/}
            <option value="Remboursement.">Remboursement</option>
            <option value="Facture">Facture/Confirmation de réservation</option>
          </select>
        </div>
              <div className="col-6 col-md-3 mb-2">
          <input className="form-control" placeholder="Rechercher une facture" onChange={(v) => {
            //console.log("Select changed ", v);
            setImmatriculation(v.target.value ? v.target.value : undefined);
          }} /> 
        </div>
        </Row>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px'  }} >
  <div className="authentication-form">
    <Row>
      <Col className="col-6">
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          dateFormat="dd/MM/yyyy"
          locale="fr"
          className={'mb-3'}
        />
      </Col>
      <Col className="col-6">
        <DatePicker
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          dateFormat="dd/MM/yyyy"
          locale="fr"
        />
      </Col>
    </Row>
    <div className="mb-3 text-center d-flex flex-column gap-2">
      <button
        style={{
          marginLeft: '10px',
          marginTop: '10px',
        }}
        className="btn btn-primary rounded-3 d-flex gap-2 fw-bold text-uppercase mt-2 mb-2"
        onClick={handleDownload}
      >
        {loading ? ( // Affichez le loader si loading est true
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "Télécharger"
        )}
      </button>
    </div>
  </div>

  <div>
    <form onSubmit={handleSubmit(onSubmit)} className="authentication-form" noValidate>
      <Row>
        <Col className="col-6">
          <FormInput
            register={register}
            control={control}
            errors={errors}
            type="text"
            name="paymentIntentId"
            required
            placeholder="Payment Intent ID"
            label={''}
            containerClass={'mb-3'}
          />
        </Col>
        <Col className="col-6">
          <FormInput
            register={register}
            control={control}
            errors={errors}
            type="text"
            name="numReservation"
            placeholder="Numéro Réservation"
            label={''}
            containerClass={'mb-3'}
          />
        </Col>
      </Row>

      <div className="mb-3 text-center d-flex flex-column gap-2">
        {/* <Button type="submit" variant="primary" disabled={isSubmitting}>
        Facturer
        </Button> */}
        <Button type="submit" variant="primary" disabled={isSubmitting}>
          {loading ? ( // Affichez le loader si loading est true
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            "Créer une facture"
          )}
        </Button>
        {/* <Button variant="light" onClick={handleClose}>
          Annuler
        </Button>{' '} */}
      </div>
    </form>
  </div>
</div>
{loading ? ( // Affichez le loader si loading est true
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
              <Table
                columns={columns}
                data={facturesDownload}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
              />
            )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <EditFormDialog
        facture={selectedFacture}
      /> */}
    </>
  );
};

export default FactureTable;
// function schemaResolver(values: any, context: object | undefined, options: ResolverOptions<IFormValue>): ResolverResult<IFormValue> | Promise<ResolverResult<IFormValue>> {
//   throw new Error('Function not implemented.');
// }

function handleClose() {
  throw new Error('Function not implemented.');
}

